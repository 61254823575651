import React from "react";

function Portfolio() {
  return (
    <div className="container" id="my_services_section">
      <div className="my-10 text-center mt-[220px]">
        <p
          data-aos="fade-down"
          className="text-5xl font-bold text-center my-5 relative "
        >
          <p className="pointer-events-none text-[220px] leading-[220px] font-black text-gray text-opacity-5 absolute z-10 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
            03
          </p>
          My Projects<span className="text-purple">.</span>
        </p>
        <p
          data-aos="fade-down"
          className="text-base font-bold leading-loose w-[90%] md:w-[55%] mx-auto text-gray text-opacity-90"
        >
          
        </p>
      </div>
      <div className="w-full">
        <div className="flex flex-col space-y-3.5 items-center justify-center">
          <div className="border rounded-2xl border-gray border-opacity-20 w-full p-6 md:p-10">
            <p
              data-aos="fade-down"
              className="text-2xl font-bold text-center text-gray mb-3"
            >
              Real Estate Portfolio
            </p>
            <p
              data-aos="fade-down"
              data-aos-delay="100"
              className="text-base font-bold leading-normal text-center text-gray text-opacity-90"
            >
              12 Units - Waco Texas
            </p>
            <div className="mt-10">
              <p
                data-aos="fade-down"
                data-aos-delay="150"
                className="text-2xl font-bold text-center text-gray"
              >
                Projections:
              </p>
            </div>
            <div className="flex justify-center gap-2.5 flex-wrap mt-6">
              <div
                data-aos="fade-down"
                data-aos-delay="200"
                className="p-5 w-full md:w-auto px-10 bg-orange text-white rounded-2xl text-center font-bold text-sm"
              >
                Purchase Price: $525,000
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="250"
                className="p-5 w-full md:w-auto px-10 bg-purple text-white rounded-2xl text-center font-bold text-sm"
              >
                Rehab: $225,000
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="300"
                className="p-5 w-full md:w-auto px-10 bg-yellow text-gray rounded-2xl text-center font-bold text-sm"
              >
                ARV: $1,000,000
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="350"
                className="p-5 w-full md:w-auto px-10 bg-orange text-white rounded-2xl text-center font-bold text-sm "
              >
                Equity Gain: $250,000
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="100"
                className="p-5 w-full md:w-auto px-10 bg-yellow text-gray rounded-2xl text-center font-bold text-sm"
              >
                Rent Rates: $720-800
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="150"
                className="p-5 w-full md:w-auto px-10 bg-purple text-white rounded-2xl text-center font-bold text-sm"
              >
                Cashflow: $64,000/Year
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="200"
                className="p-5 w-full md:w-auto px-10 bg-orange text-white rounded-2xl text-center font-bold text-sm"
              >
                Timeline: 13 Months
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
