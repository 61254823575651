import React from "react";

function TimelineCard({ Company, Role, Location }) {
  return (
    <div className="pb-12 relative single_timeline" >
      <div className="flex flex-col border-solid border-2 rounded-xl border-lightGray p-5 justify-between text-lightwhite hover:bg-lightGray">
        <div className="flex flex-col">
          <span className="text-md mb-2">{Company}</span>
          <span className="text-sm opacity-75">{Role}</span>
        </div>
        <div className="text-xs opacity-50 mt-6">
          <span>{Location}</span>
        </div>
      </div>
    </div>
  );
}

export default TimelineCard;
