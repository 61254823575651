import React from "react";

function Portfolio() {
  return (
    <div className="container mt-10" id="my_services_section">
      <div className="w-full">
        <div className="flex flex-col space-y-3.5 items-center justify-center">
          <div className="border rounded-2xl border-gray border-opacity-20 w-full p-6 md:p-10">
            <p
              data-aos="fade-down"
              className="text-2xl font-bold text-center text-gray mb-3"
            >
              Credit Specialist
            </p>
            <p
              data-aos="fade-down"
              data-aos-delay="100"
              className="text-base font-bold leading-normal text-center text-gray text-opacity-90"
            >
              On a Network Basis, I Help Friends and Family Scale and Leverage Their Credit for Business
            </p>
            <div className="mt-10">
              <p
                data-aos="fade-down"
                data-aos-delay="150"
                className="text-2xl font-bold text-center text-gray"
              >
                Statistics:
              </p>
            </div>
            <div className="flex justify-center gap-2.5 flex-wrap mt-6">
              <div
                data-aos="fade-down"
                data-aos-delay="200"
                className="p-5 w-full md:w-auto px-10 bg-orange text-white rounded-2xl text-center font-bold text-sm"
              >
                Client Count: 28+
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="250"
                className="p-5 w-full md:w-auto px-10 bg-purple text-white rounded-2xl text-center font-bold text-sm"
              >
                Credit Lines Gained: $1,100,000+
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="300"
                className="p-5 w-full md:w-auto px-10 bg-yellow text-gray rounded-2xl text-center font-bold text-sm"
              >
                Sign Up Bonuses Earned: $42,000+
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="350"
                className="p-5 w-full md:w-auto px-10 bg-orange text-white rounded-2xl text-center font-bold text-sm "
              >
                # of Card Approvals: 130+
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="100"
                className="p-5 w-full md:w-auto px-10 bg-yellow text-gray rounded-2xl text-center font-bold text-sm"
              >
                Businesses Launched: 10+
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
