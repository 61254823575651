import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import Aave from "../components/body/aave";
import Bitcoin from "../components/body/bitcoin";
import Cryptoport from "../components/body/cryptoport";
import Eth from "../components/body/eth";
import Portfolio from "../components/body/Portfolio";
import Portfolio2 from "../components/body/Portfolio2";
import Portfolio3 from "../components/body/Portfolio3";
import Portfolio4 from "../components/body/Portfolio4";
import Shortline from "../components/body/shortline";
import Timeline from "../components/body/Timeline";
import Vechain from "../components/body/vechain";
import Button from "../components/Button";
import DesktopHeader from "../components/DesktopHeader";
import Footer from "../components/footer/footer";
import Dropdown from "../components/header/Dropdown";
import Header from "../components/header/header";
import HeroSection from "../components/HeroSection";
import { openPopupWidget } from "react-calendly";
import { calendlyConfig } from "..";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
        console.log("i resize");
      }
    };
    window.addEventListener("resize", hideMenu);
    return () => {
      window.removeEventListener("resize", hideMenu);
    };
  });

  // const container = useRef(null);

  // React.useEffect(() => {
  //   lottie.loadAnimation({
  //     container: container.current,
  //     renderer: "svg",
  //     loop: false,
  //     animationData: require("../components/body/mobilelottie.json"),
  //   }).setSpeed(2)
  // }, []);

  AOS.init();

  return (
    <>
      <div className="">
        <div className="relative">
          <Header toggle={toggle} />
          <Dropdown isOpen={isOpen} toggle={toggle} />
        </div>
        <DesktopHeader />

        <HeroSection />

        {/* About Me */}
        <div
          className="flex flex-row py-14 pt-4 md:py-24 justify-center items-center bg-yellow"
          id="about_section"
        >
          {/* Left About Me */}
          <div className="flex flex-col md:flex-row items-center container justify-between ">
            <div data-aos="fade-down" className="mb-5">
              <div className="w-[350px] md:w-[490px] aspect-square mt-[90px] rounded-full flex items-center justify-center bg-lightwhite/80">
                <div className="w-[270px] md:w-[402px] aspect-square rounded-full flex items-center justify-center bg-yellow">
                  <img
                    src={require("../assets/img/men-masked.png")}
                    alt="Men Photo"
                    className="-mt-[109px] max-w-full"
                  />
                </div>
              </div>
            </div>
            {/* Right About Me */}
            <div className="flex flex-col md:w-[600px] space-y-[30px] px-8 relative ">
              <div data-aos="fade-down" className="py-4">
                <Shortline />
              </div>

              <div data-aos="fade-down" className="text-5xl font-bold">
                About Me<span className="text-orange">.</span>
              </div>
              <p className="text-[220px] leading-[220px] font-black text-gray text-opacity-5 absolute z-10 top-[-35px] left-[-50px] pointer-events-none">
                02
              </p>

              <div data-aos="fade-down" className="font-bold">
                Hi, I’m Joey.{" "}
              </div>
              <div data-aos="fade-down">
                At 26 years old - I am an ambitious, goal seeking, and profit
                driven individual. For example, in May of 2020, I began working
                with Project Wifi in an unpaid intern role. Within 12 months, I
                had directly delivered approximately $600,000 of revenue to the
                company from my personal network, not including sales created
                from 3rd party leads.
              </div>
              <div data-aos="fade-down">
                In addition to my sales success, I have masterminded and
                executed on multiple infrastructure changing concepts that lead
                to the growth and stustainability we have seen since my
                induction into the company. My existing network has also
                delivered ongoing staff to our organization, along with multiple
                current and previous business to business partnerships. I now
                serve as CFO for Project Wifi.
              </div>
              <div data-aos="fade-down">
                <Button
                  type="outline"
                  onClick={() => openPopupWidget(calendlyConfig)}
                >
                  Contact Me
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio */}
        <Portfolio />
        <Portfolio2 />
        <Portfolio3 />
        <Portfolio4 />

        {/* Devider --Start-- */}
        <div className="container my-16 md:my-24">
          <div className="w-full h-0.5 bg-lightwhite"></div>
        </div>
        {/* Devider --End-- */}

        {/* Crypto Section*/}
        <p
          data-aos="fade-down"
          className="text-2xl font-bold text-center text-gray"
        >
          Current Crypto Positions
        </p>
        <div className="container mb-28">
          <div
            data-aos="fade-down"
            className="mt-8 md:mb-11 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5"
          >
            <Cryptoport
              title="Bitcoin "
              icon={<Bitcoin />}
              // price="$51,055 BTC"
            />
            <Cryptoport
              title="Vechain "
              icon={<Vechain />}
              // price="$51,055 BTC"
            />
            <Cryptoport
              title=" Aave "
              icon={<Aave />}
              // price="$51,055 BTC"
            />
            <Cryptoport
              title="Ethereum "
              icon={<Eth />}
              // price="$51,055 BTC"
            />
          </div>
        </div>

        {/* Timeline */}

        <Timeline />

        {/* Contact Me*/}
        <section className="bg-purple py-24 md:py-32">
          <div className="container flex flex-col items-center">
            <div
              data-aos="fade-down"
              className="text-white text-5xl font-semibold pb-[40px] text-center"
            >
              Looking to Connect with Joey Fraser?
            </div>
            <p
              data-aos="fade-down"
              className="text-base font-semibold leading-loose w-[90%] md:w-[55%] mx-auto text-white text-center text-opacity-90"
            >
              I enjoy connecting with likeminded individuals, and encourage you
              to book a call if you believe I can bring you value or vice versa.
              Cheers!
            </p>

            <div data-aos="fade-down" className="  mt-12 w-full w-auto">
              <Button
                className="mx-auto bg-orange text-white hover:opacity-50"
                type="outline"
                onClick={() => openPopupWidget(calendlyConfig)}
              >
                Book A Call
              </Button>
            </div>
          </div>
        </section>
        {/* <Contact /> */}

        {/* Footer*/}
        <Footer />
      </div>
    </>
  );
}

export default Home;
