function HeaderStatistic() {
    return (
        <>
            {/* Statistic Section --Start-- */}
            <section className="container mb-20 mt-20 md:mt-0 flex flex-col items-center md:block">
                <p className="subtitle dark font-bold mt-5 mb-6" data-aos='fade-down'
                >Door to Door and Cold Call Results.</p>

                <div className="flex flex-col md:flex-row md:items-center gap-10">
                    <div className="flex items-center gap-5" data-aos='fade-down'
                        data-aos-delay="100" >
                        <div className="w-[76px] h-[76px] rounded-full bg-purple flex items-center justify-center">
                            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.955 0.850001C6.67498 0.880001 -0.0300218 7.615 -2.17905e-05 15.895C0.0299782 24.175 6.76498 30.88 15.045 30.85C23.325 30.82 30.03 24.085 30 15.805C29.97 7.525 23.235 0.835001 14.955 0.850001ZM18.39 16C19.56 16.405 21.135 17.275 21.135 19.96C21.135 22.27 19.32 24.13 17.1 24.13H16.125V25C16.125 25.615 15.615 26.125 15 26.125C14.385 26.125 13.875 25.615 13.875 25V24.13H13.335C10.875 24.13 8.87998 22.06 8.87998 19.51C8.87998 18.895 9.38998 18.385 10.005 18.385C10.62 18.385 11.13 18.895 11.13 19.51C11.13 20.815 12.12 21.88 13.335 21.88H13.875V16.81L11.61 16C10.44 15.595 8.86498 14.725 8.86498 12.04C8.86498 9.73 10.68 7.87 12.9 7.87H13.875V7C13.875 6.385 14.385 5.875 15 5.875C15.615 5.875 16.125 6.385 16.125 7V7.87H16.665C19.125 7.87 21.12 9.94 21.12 12.49C21.12 13.105 20.61 13.615 19.995 13.615C19.38 13.615 18.87 13.105 18.87 12.49C18.87 11.185 17.88 10.12 16.665 10.12H16.125V15.19L18.39 16Z" fill="white" />
                            </svg>
                        </div>
                        <div>
                            <h3 className="text-3xl font-black text-gray" >$3.5 Million+</h3>
                            <p className="font-bold text-lightGray mt-1" >In Direct Profit Produced</p>
                        </div>
                    </div>

                    <div className="flex items-center gap-5" data-aos='fade-down'
                        data-aos-delay="200">
                        <div className="w-[76px] h-[76px] rounded-full bg-orange flex items-center justify-center">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM10.665 22.35C10.665 22.77 10.335 23.1 9.915 23.1H5.73C5.31 23.1 4.98 22.77 4.98 22.35V15.42C4.98 14.475 5.745 13.71 6.69 13.71H9.915C10.335 13.71 10.665 14.04 10.665 14.46V22.35ZM17.835 22.35C17.835 22.77 17.505 23.1 17.085 23.1H12.9C12.48 23.1 12.15 22.77 12.15 22.35V8.61C12.15 7.665 12.915 6.9 13.86 6.9H16.14C17.085 6.9 17.85 7.665 17.85 8.61V22.35H17.835ZM25.02 22.35C25.02 22.77 24.69 23.1 24.27 23.1H20.085C19.665 23.1 19.335 22.77 19.335 22.35V17.025C19.335 16.605 19.665 16.275 20.085 16.275H23.31C24.255 16.275 25.02 17.04 25.02 17.985V22.35Z" fill="white" />
                            </svg>

                        </div>
                        <div>
                            <h3 className="text-3xl font-black text-gray" >4+ Industries</h3>
                            <p className="font-bold text-lightGray mt-1" >Sales Experience</p>
                        </div>
                    </div>
                </div>

            </section>
            {/* Statistic Section --End-- */}
        </>
    )
}

export default HeaderStatistic;
