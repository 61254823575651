import React from "react";

function button() {
  return (
    <div className="hidden md:flex md:box-border md:border-solid md:border-2 md:border-gray/10 md:rounded-xl md:justify-center md:items-center md:bg-white md:w-[190px] md:h-[70px] ">
      <button>Contact Me</button>
    </div>
  );
}

export default button;
