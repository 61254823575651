import React from "react";

function shortline() {
  return (
    <div className="w-full block">
      <svg
        width="100%"
        height="3"
        viewBox="0 0 517 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1.31134e-07"
          y1="1.5"
          x2="517"
          y2="1.50005"
          stroke="black"
          stroke-width="3"
        />
      </svg>
    </div>
  );
}

export default shortline;
