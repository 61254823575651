function Button({ type = 'default', children = 'Button', className = '', ...props }) {
    const btnColor = type === 'outline' ? 'border-gray/10 text-gray bg-white hover:bg-gray/5' : 'bg-orange text-white hover:bg-orange/90 border-orange hover:border-orange'
    return (
        <button className={`py-3.5 px-8 rounded-[15px] outline-none duration-150 border-2 ${btnColor} ${className}`} {...props} >
            {children}
        </button>
    )
}

export default Button
