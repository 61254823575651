import React from "react";

function cryptoport({ title, icon, price }) {
  return (
    <div className="rounded-xl text-center py-[35px] px-[30px] border border-gray border-opacity-20">
      <div className="w-[60px] mx-auto mb-5 aspect-square rounded-full flex items-center justify-center">
        {icon}
      </div>
      <div className="font-bold mb-2">{title}</div>
      <div> {price}</div>
    </div>
  );
}

export default cryptoport;
