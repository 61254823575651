/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from "react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import TimelineCard from "./TimelineCard";

function Timeline() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const paginationRef = useRef(null);

  const getPageFraction = () => {
    const fractionText = document.querySelector(
      ".swiper-pagination-fraction"
    ).textContent;
    paginationRef.current.textContent = fractionText;
  };

  useEffect(() => {
    getPageFraction();
  }, []);

  return (
    <div className="bg-gray" id="timeline_section">
      <div className="container py-28 pb-36 pt-32 relative">
        <p className="pointer-events-none text-[220px] leading-[220px] font-black text-white/5 text-opacity-5 absolute z-10 left-[-70px] top-[50px]">
          04
        </p>

        <div className="flex flex-col ">
          <div
            data-aos="fade-down"
            className="text-white text-5xl font-semibold pb-[40px]"
          >
            Timeline<span className="text-purple">.</span>
          </div>
          <div className="timeline_slider relative" data-aos="fade-down">
            {/* Custom Slider Navigation --Start-- */}
            <div className="absolute text-white left-0 -bottom-16 flex items-center gap-1">
              <div ref={prevRef} className="py-2 px-1 pl-0 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <div ref={nextRef} className="py-2 px-1 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>

            <div
              className="text-white absolute right-0 -bottom-16 pointer-events-none"
              ref={paginationRef}
            ></div>
            {/* Custom Slider Navigation --End-- */}

            <Swiper
              pagination={{
                type: "fraction",
              }}
              spaceBetween={20}
              slidesPerView={3.5}
              onSlideChange={(e) => getPageFraction()}
              onSwiper={(swiper) => getPageFraction()}
              modules={[Pagination, Navigation]}
              navigation
              breakpoints={{
                0: {
                  slidesPerView: 1.2,
                },
                600: {
                  slidesPerView: 2.2,
                },
                768: {
                  slidesPerView: 3.3,
                },
              }}
              onInit={(swiper) => {
                console.log("onInit", swiper);
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;

                swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              <SwiperSlide>
                <TimelineCard
                  Company={"BlackWater"}
                  Role={"Founder & CFO"}
                  Location={"Las Vegas, Nevada"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"12 Unit Multi-Family Acquisition"}
                  Role={"Real Estate Investor"}
                  Location={"Waco, Texas"}
                />
              </SwiperSlide>
<SwiperSlide>
                <TimelineCard
                  Company={"12 Unit Multi-Family Acquisition"}
                  Role={"Real Estate Investor"}
                  Location={"Waco, Texas"}
                />
              </SwiperSlide>

              <SwiperSlide>
                <TimelineCard
                  Company={"Project Wifi"}
                  Role={"Founder & CFO"}
                  Location={"Las Vegas, Nevada"}
                />
              </SwiperSlide>

              <SwiperSlide>
                <TimelineCard
                  Company={"Consumer Credit Professional"}
                  Role={"Manager for @KingCredit"}
                  Location={"Los Angeles, California"}
                />
              </SwiperSlide>

              <SwiperSlide>
                <TimelineCard
                  Company={"Sherwin Williams Pain"}
                  Role={"Assistant Manager"}
                  Location={"Portland, Oregon"}
                />
              </SwiperSlide>

              <SwiperSlide>
                <TimelineCard
                  Company={"Big Guy Properties"}
                  Role={"Assistant Property Manager"}
                  Location={"Dayton, Ohio"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"American International Forest Products"}
                  Role={"Lumber Broker"}
                  Location={"Beaverton, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"Falls City High School"}
                  Role={"Entrepreneurship Course Instructor"}
                  Location={"Fall City, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"Nice Menswear"}
                  Role={"Owner"}
                  Location={"Corvallis, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"McKay Creek Painting"}
                  Role={"Chief Sales Officer"}
                  Location={"Hillsboro, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"College Works Painting"}
                  Role={"District Manager"}
                  Location={"Corvallis, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"MU Basement"}
                  Role={"Front Desk Representative"}
                  Location={"Corvallis, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"Cove Palisades State Park"}
                  Role={"Park Ranger Assistant"}
                  Location={"Culver, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"Earth2o"}
                  Role={"Blow Molding Injection Operator"}
                  Location={"Culver, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"Madras Bowl & Pizza"}
                  Role={"Pizza Chef"}
                  Location={"Madras, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"Culver Market"}
                  Role={"Janitor"}
                  Location={"Culver, Oregon"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TimelineCard
                  Company={"Onion Field Farmer"}
                  Role={"Intern"}
                  Location={"Culver, Oregon"}
                />
              </SwiperSlide>
            </Swiper>
          </div>
          {/*           
          <TimelineCard
            Company={"Sherwin Williams Pain"}
            Role={"Assistant Manager"}
            Location={"Portland, OR"}
          />
          <div>
            <svg
              width="12"
              height="52"
              viewBox="0 0 12 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33812 2.00714C7.34265 1.17873 6.67477 0.503485 5.84635 0.498949C5.01794 0.494413 4.3427 1.1623 4.33816 1.99071L7.33812 2.00714ZM7.06486 51.9081L7.33812 2.00714L4.33816 1.99071L4.06491 51.8917L7.06486 51.9081Z"
                fill="white"
                fill-opacity="0.15"
              />
              <path
                d="M5.973 22.0561C8.71951 22.0251 10.9687 24.0095 10.9966 26.4883C11.0246 28.9671 8.82078 31.0017 6.07427 31.0326C3.32777 31.0636 1.07861 29.0793 1.05064 26.6005C1.02268 24.1217 3.22649 22.0871 5.973 22.0561Z"
                fill="#6B56ED"
              />
            </svg>
          </div>

          <TimelineCard
            Company={"Big Guy Properties"}
            Role={"Assistant Manager"}
            Location={"Dayton, OH"}
          />
          <div>
            <svg
              width="12"
              height="52"
              viewBox="0 0 12 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33812 2.00714C7.34265 1.17873 6.67477 0.503485 5.84635 0.498949C5.01794 0.494413 4.3427 1.1623 4.33816 1.99071L7.33812 2.00714ZM7.06486 51.9081L7.33812 2.00714L4.33816 1.99071L4.06491 51.8917L7.06486 51.9081Z"
                fill="white"
                fill-opacity="0.15"
              />
              <path
                d="M5.973 22.0561C8.71951 22.0251 10.9687 24.0095 10.9966 26.4883C11.0246 28.9671 8.82078 31.0017 6.07427 31.0326C3.32777 31.0636 1.07861 29.0793 1.05064 26.6005C1.02268 24.1217 3.22649 22.0871 5.973 22.0561Z"
                fill="#6B56ED"
              />
            </svg>
          </div>

          <TimelineCard
            Company={"Falls City High School"}
            Role={"Entrepreneaurship Course Instructor"}
            Location={"Falls City, OR"}
          />
          <div>
            <svg
              width="12"
              height="52"
              viewBox="0 0 12 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33812 2.00714C7.34265 1.17873 6.67477 0.503485 5.84635 0.498949C5.01794 0.494413 4.3427 1.1623 4.33816 1.99071L7.33812 2.00714ZM7.06486 51.9081L7.33812 2.00714L4.33816 1.99071L4.06491 51.8917L7.06486 51.9081Z"
                fill="white"
                fill-opacity="0.15"
              />
              <path
                d="M5.973 22.0561C8.71951 22.0251 10.9687 24.0095 10.9966 26.4883C11.0246 28.9671 8.82078 31.0017 6.07427 31.0326C3.32777 31.0636 1.07861 29.0793 1.05064 26.6005C1.02268 24.1217 3.22649 22.0871 5.973 22.0561Z"
                fill="#6B56ED"
              />
            </svg>
          </div>

          <TimelineCard
            Company={"Sherwin Williams Pain"}
            Role={"Assistant Manager"}
            Location={"Portland, OR"}
          />
          <div>
            <svg
              width="12"
              height="52"
              viewBox="0 0 12 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33812 2.00714C7.34265 1.17873 6.67477 0.503485 5.84635 0.498949C5.01794 0.494413 4.3427 1.1623 4.33816 1.99071L7.33812 2.00714ZM7.06486 51.9081L7.33812 2.00714L4.33816 1.99071L4.06491 51.8917L7.06486 51.9081Z"
                fill="white"
                fill-opacity="0.15"
              />
              <path
                d="M5.973 22.0561C8.71951 22.0251 10.9687 24.0095 10.9966 26.4883C11.0246 28.9671 8.82078 31.0017 6.07427 31.0326C3.32777 31.0636 1.07861 29.0793 1.05064 26.6005C1.02268 24.1217 3.22649 22.0871 5.973 22.0561Z"
                fill="#6B56ED"
              />
            </svg>
          </div>

          <TimelineCard
            Company={"Sherwin Williams Pain"}
            Role={"Assistant Manager"}
            Location={"Portland, OR"}
          />
          <div>
            <svg
              width="12"
              height="52"
              viewBox="0 0 12 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33812 2.00714C7.34265 1.17873 6.67477 0.503485 5.84635 0.498949C5.01794 0.494413 4.3427 1.1623 4.33816 1.99071L7.33812 2.00714ZM7.06486 51.9081L7.33812 2.00714L4.33816 1.99071L4.06491 51.8917L7.06486 51.9081Z"
                fill="white"
                fill-opacity="0.15"
              />
              <path
                d="M5.973 22.0561C8.71951 22.0251 10.9687 24.0095 10.9966 26.4883C11.0246 28.9671 8.82078 31.0017 6.07427 31.0326C3.32777 31.0636 1.07861 29.0793 1.05064 26.6005C1.02268 24.1217 3.22649 22.0871 5.973 22.0561Z"
                fill="#6B56ED"
              />
            </svg>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
