import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

export const calendlyConfig = {
  prefill: {
    customAnswers: {
      a1: "a1",
      a10: "a10",
      a2: "a2",
      a3: "a3",
      a4: "a4",
      a5: "a5",
      a6: "a6",
      a7: "a7",
      a8: "a8",
      a9: "a9",
    },
  },
  url: "https://calendly.com/imjoey",

  pageSettings: {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideGdprBanner: true,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
