import { openPopupWidget } from "react-calendly";
import { calendlyConfig } from "..";
import menPng from "../assets/img/men.png";
import Button from "./Button";
import HeaderStatistic from "./HeaderStatistic";

function HeroSection() {
  return (
    <div className="" id="hero_section">
      <section className="container relative">
        <p className="pointer-events-none text-[220px] leading-[220px] font-black text-gray text-opacity-5 absolute z-10 left-1/2 md:left-[-60px] top-1/2 md:top-[120px] -translate-x-1/2 -translate-y-1/2 md:translate-x-0 md:translate-y-0">
          01
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 justify-items-end place-items-center gap-10 pt-8">
          {/* Left Grid Box --Start-- */}
          <div className="flex flex-col items-center md:block" >
            {/* Email Box --Start-- */}
            <a

              href="mailto:info@imjoey.me"
              className="flex items-center justify-center w-fit rounded-md bg-purple/20 text-purple font-semibold gap-2 px-8 md:px-4 py-2"
            >
              <svg
                width="25"
                height="21"
                viewBox="0 0 25 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5417 0.229248H6.45835C2.83335 0.229248 0.416687 2.04175 0.416687 6.27092V14.7292C0.416687 18.9584 2.83335 20.7709 6.45835 20.7709H18.5417C22.1667 20.7709 24.5834 18.9584 24.5834 14.7292V6.27092C24.5834 2.04175 22.1667 0.229248 18.5417 0.229248ZM19.1096 7.588L15.3275 10.6088C14.53 11.2492 13.515 11.5634 12.5 11.5634C11.485 11.5634 10.4579 11.2492 9.67252 10.6088L5.89044 7.588C5.50377 7.27383 5.44335 6.69383 5.74544 6.30717C6.0596 5.9205 6.62752 5.848 7.01419 6.16217L10.7963 9.183C11.7146 9.92008 13.2734 9.92008 14.1917 9.183L17.9738 6.16217C18.3604 5.848 18.9404 5.90842 19.2425 6.30717C19.5567 6.69383 19.4963 7.27383 19.1096 7.588Z"
                  fill="#6B56ED"
                />
              </svg>
              <p>info@imjoey.me</p>
            </a>
            {/* Email Box --End-- */}
            <h1

              data-aos-delay="100"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              className="font-extrabold text-[45px] md:text-[84px] text-gray mt-4"
            >
              I’m <span className="text-purple">Joey</span>!
            </h1>



            <p

              data-aos-delay="200"
              className="subtitle md:hidden dark text-2xl font-bold mt-2"
            >
              Quick Fact
            </p>

            <p

              data-aos-delay="200"
              className="subtitle hidden md:before: dark font-bold mt-5"
            >
              This is a paragraph heading for the hero.
            </p>

            <p

              data-aos-delay="300"
              className="subtitle text-center md:text-left font-semibold md:font-normal mt-4 w-[80%]"
            >
              I have a passion for finance, credit, and real estate projects with extensive experience in sales and business development.
            </p>

            <div className="mt-6 hidden md:block" data-aos-delay="400">
              <Button className="flex items-center gap-3" onClick={() => openPopupWidget(calendlyConfig)}>
                Let’s Talk
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  className="-mt-1"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.2396 17.5313L19.6458 20.823C19.75 21.6875 18.8229 22.2917 18.0833 21.8438L14.4792 19.698C14.2292 19.5521 14.1667 19.2396 14.3021 18.9896C14.8229 18.0313 15.1042 16.948 15.1042 15.8646C15.1042 12.0521 11.8333 8.94796 7.81251 8.94796C6.9896 8.94796 6.18751 9.07296 5.43751 9.32296C5.05209 9.44796 4.67709 9.09379 4.77084 8.69796C5.71876 4.90629 9.3646 2.08337 13.7188 2.08337C18.8021 2.08337 22.9167 5.92712 22.9167 10.6667C22.9167 13.4792 21.4688 15.9688 19.2396 17.5313Z"
                    fill="white"
                  />
                  <path
                    d="M13.5417 15.8645C13.5417 17.1041 13.0833 18.25 12.3125 19.1562C11.2813 20.4062 9.64584 21.2083 7.81251 21.2083L5.09376 22.8229C4.63543 23.1041 4.05209 22.7187 4.11459 22.1875L4.37501 20.1354C2.97918 19.1666 2.08334 17.6145 2.08334 15.8645C2.08334 14.0312 3.06251 12.4166 4.56251 11.4583C5.48959 10.8541 6.60418 10.5104 7.81251 10.5104C10.9792 10.5104 13.5417 12.9062 13.5417 15.8645Z"
                    fill="white"
                  />
                </svg>
              </Button>

            </div>
          </div>
          {/* Left Grid Box --End-- */}

          {/* Right Grid Box --Start-- */}
          <div data-aos="fade-right" className="mt-5 md:mt-0" data-aos-delay="200">
            <img src={menPng} alt="Men Photo" className="-ml-5 scale-[1.2] md:scale-100" />
          </div>
          {/* Right Grid Box --End-- */}
        </div>
      </section>
      <HeaderStatistic />
    </div>
  );
}

export default HeroSection;
