import { openPopupWidget } from "react-calendly";
import { calendlyConfig } from "..";
import logo from "../assets/img/logo.png";
import Button from "./Button";

function DesktopHeader() {
  const navLinks = [
    {
      title: "Home",
      hashId: "#hero_section",
    },
    {
      title: "About",
      hashId: "#about_section",
    },
    {
      title: "My Services",
      hashId: "#my_services_section",
    },
    {
      title: "Timeline",
      hashId: "#timeline_section",
    },
  ];
  return (
    <>
      <header className="hidden md:block ">
        <div className="container">
          <div className="flex items-center justify-between py-6 border-b border-lightwhite/80">
            {/* Logo --Start-- */}
            <div>
              <img src={logo} alt="JOEY" />
            </div>
            {/* Logo --End-- */}

            {/* Nav links --Start-- */}
            <nav>
              <ul className="flex items-center gap-5">
                {navLinks.map((navLink, index) => (
                  <li key={index}>
                    <a
                      href={navLink.hashId}
                      className={`font-bold duration-150 text-gray text-base px-2 py-1 hover:text-purple ${
                        index === 0 ? "hover:text-purple text-purple" : ""
                      }`}
                    >
                      {navLink.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            {/* Nav links --End-- */}

            {/* Contact me button --Start-- */}
            <div>
              <Button
                type="outline"
                onClick={() => openPopupWidget(calendlyConfig)}
              >
                Contact Me
              </Button>
            </div>
            {/* Contact me button --End-- */}
          </div>
        </div>
      </header>
    </>
  );
}

export default DesktopHeader;
