import React from "react";
import { Link } from "react-router-dom";
import Button from "../hero/button";

function header({ toggle }) {
  return (
    <>
      <nav className="flex h-16 justify-between items-center md:max-w-[1340px] md:pt-[70px] md:container md:mx-auto bg-white text-black shadow-sm md:hidden">
        <Link to="/" className="pl-8">
          <img src={require('../../assets/img/logo.png')} className="w-[95px]" alt="Koey" />
        </Link>

        <div className="px-4 cursor-pointer md:hidden" onClick={toggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        <div className="pr-8 md:block hidden">
          <Link className="p-4" to="#hero_section">
            Home
          </Link>
          <Link className="p-4" to="#about_section">
            About
          </Link>
          <Link className="p-4" to="#my_services_section">
            My Services
          </Link>
          <Link className="p-4" to="#timeline_section">
            Timeline
          </Link>
        </div>
        <Button />
      </nav>
    </>
  );
}

export default header;
