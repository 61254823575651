import React from "react";

const Dropdown = ({ isOpen, toggle }) => {
  // isOpen
  //         ? "flex flex-col text-center items-center  bg-lightGray text-lightwhite"
  //         : "hidden"
  return (
    <div
      className={
        `flex flex-col text-center items-center  bg-lightGray text-lightwhite absolute top-full left-0 w-full z-50 ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} duration-700`
      }
      onClick={toggle}
    >
      <a className="p-4 w-full block" href="#hero_section">
        Home
      </a>
      <a className="p-4 w-full block" href="#about_section">
        About
      </a>
      <a className="p-4 w-full block" href="#my_services_section">
        Portfolio
      </a>
      <a className="p-4 w-full block" href="#timeline_section">
        Timeline
      </a>
    </div>
  );
};

export default Dropdown;
